import { PropsWithChildren } from "react";

import { Link, LinkProps } from "@remix-run/react";
import cx from "classix";

export const LinkButton = ({ children, ...props }: PropsWithChildren<LinkProps>) => {
  return (
    <Link
      className={cx("bg-bluePrimary active:bg-bluePressed text-black px-4 py-3 w-full rounded-3xl flex justify-center")}
      {...props}
    >
      {children}
    </Link>
  );
};
