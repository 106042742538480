const PageNotFoundIllustration = () => (
  <svg width="384" height="385" viewBox="0 0 384 385" fill="none">
    <path
      d="M244.811 140.809C241.12 158.815 225.184 172.372 206.091 172.372C186.997 172.372 172.416 159.945 167.904 143.071C163.147 141.759 159.648 137.407 159.648 132.233C159.648 127.06 163.328 122.473 168.288 121.3C168.469 120.713 168.661 120.137 168.864 119.561"
      stroke="#E0E2F2"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M212.352 114.185C211.232 114.463 210.059 114.612 208.853 114.612C205.984 114.612 203.317 113.78 201.067 112.34C198.837 117.535 193.685 121.161 187.691 121.161C183.627 121.161 180.64 127.273 178.88 128.948C176.533 130.687 172.085 119.583 168.939 119.583C161.109 119.583 154.763 113.225 154.763 105.396C154.763 97.5666 161.056 91.2733 168.843 91.2199C170.187 84.5746 176.064 79.5719 183.115 79.5719C185.653 79.5719 188.043 80.2226 190.123 81.3746C191.275 77.0119 195.232 73.8013 199.947 73.8013C202.923 73.8013 205.611 75.0919 207.467 77.1293C209.323 75.0919 212.011 73.8013 214.987 73.8013C220.597 73.8013 225.152 78.3559 225.152 83.9666C225.152 84.4039 225.12 84.8413 225.067 85.2679C226.891 84.4253 228.928 83.9666 231.072 83.9666C239.104 83.9666 245.621 90.4839 245.621 98.5159C245.621 103.369 243.253 107.668 239.605 110.313"
      stroke="#E0E2F2"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M212.085 171.433L212.64 183.231C212.736 185.289 214.133 187.06 216.107 187.636L223.723 189.855"
      stroke="#E0E2F2"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M174.187 156.169V180.009C174.187 184.031 171.691 187.636 167.925 189.055L163.787 190.612"
      stroke="#E0E2F2"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M137.685 283.348H106.571C105.376 283.348 101.259 281.833 101.333 280.639C102.133 268.863 110.539 209.481 126.453 200.265C140.224 192.287 163.083 188.724 163.083 188.724C163.083 188.724 169.013 209.759 196.053 208.745C223.093 207.732 223.733 189.855 223.733 189.855C223.733 189.855 238.645 192.297 252.075 198.239C266.389 204.575 276.619 212.969 278.88 213.567"
      stroke="#E0E2F2"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M281.6 230.9C280.103 228.425 278.921 196.553 277.598 189.72C276.025 181.542 269.604 159.032 269.604 159.032C269.604 159.032 264.517 160.043 258.443 155.459C252.369 150.875 244.267 140.233 244.267 140.233"
      stroke="#E0E2F2"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M276.373 108.041C276.373 108.041 273.749 101.471 265.717 101.471C257.685 101.471 244.832 112.415 244.832 112.415"
      stroke="#E0E2F2"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M247.467 133.833C247.467 133.833 251.654 142.165 258.197 142.578C264.74 142.991 266.667 144.5 266.667 144.5"
      stroke="#E0E2F2"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path d="M138.144 254.431V359.529" stroke="#E0E2F2" strokeWidth="2" strokeMiterlimit="10" />
    <path
      d="M268.629 253.236C268.629 253.236 281.611 263.839 313.461 261.641C316.565 261.428 314.304 233.407 314.304 226.036C314.304 218.665 308.053 194.303 305.6 188.703C303.147 183.103 287.467 137.033 287.467 137.033"
      stroke="#E0E2F2"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M112.555 283.348C112.555 283.348 109.675 295.284 109.323 303.924C108.971 312.564 112.256 359.529 112.256 359.529"
      stroke="#E0E2F2"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M280.235 221.695C280.235 221.695 270.272 232.116 269.323 244.159C268.661 252.596 268.576 261.012 268.587 265.289C268.587 266.644 267.189 267.551 265.952 266.985L247.509 258.655C246.272 258.1 244.875 259.039 244.928 260.393L248.896 356.969"
      stroke="#E0E2F2"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M263.872 141.961C255.68 141.428 249.205 134.623 249.205 126.292C249.205 117.961 255.104 111.785 262.752 110.74"
      stroke="#8F8BEF"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M272.704 144.564C282.789 144.564 290.965 136.388 290.965 126.303C290.965 116.217 282.789 108.041 272.704 108.041C262.619 108.041 254.443 116.217 254.443 126.303C254.443 136.388 262.619 144.564 272.704 144.564Z"
      stroke="#8F8BEF"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M232 139.167C239.364 139.167 245.333 133.436 245.333 126.367C245.333 119.297 239.364 113.567 232 113.567C224.636 113.567 218.667 119.297 218.667 126.367C218.667 133.436 224.636 139.167 232 139.167Z"
      stroke="#8F8BEF"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M273.067 139.167C280.136 139.167 285.867 133.436 285.867 126.367C285.867 119.297 280.136 113.567 273.067 113.567C265.997 113.567 260.267 119.297 260.267 126.367C260.267 133.436 265.997 139.167 273.067 139.167Z"
      stroke="#8F8BEF"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M222.037 142.025C213.845 141.492 207.371 134.687 207.371 126.356C207.371 118.025 213.269 111.849 220.917 110.804"
      stroke="#8F8BEF"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M230.88 144.617C240.965 144.617 249.141 136.442 249.141 126.356C249.141 116.271 240.965 108.095 230.88 108.095C220.795 108.095 212.619 116.271 212.619 126.356C212.619 136.442 220.795 144.617 230.88 144.617Z"
      stroke="#8F8BEF"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
);

export default PageNotFoundIllustration;
