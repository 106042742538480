export type AppState = {
  isFontScalingWidgetOpen: boolean;
};

export type AppAction = { type: "OPEN_FONT_SCALING_WIDGET" } | { type: "CLOSE_FONT_SCALING_WIDGET" };

export const appReducer = (state: AppState, action: AppAction): AppState => {
  switch (action.type) {
    case "OPEN_FONT_SCALING_WIDGET":
      return { ...state, isFontScalingWidgetOpen: true };
    case "CLOSE_FONT_SCALING_WIDGET":
      return { ...state, isFontScalingWidgetOpen: false };
    default:
      return state;
  }
};

export const initialAppState: AppState = {
  isFontScalingWidgetOpen: false,
};
