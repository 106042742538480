const GeneralErrorIllustration = () => (
  <svg width="360" height="361" viewBox="0 0 360 361" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M175.29 123.761C175.29 123.761 172.62 113.841 180 108.621C187.38 103.401 196.74 107.001 200.88 107.361C205.02 107.721 210.05 106.821 210.95 103.941C211.85 101.061 207.53 93.1505 213.11 88.1105C218.69 83.0705 228.17 86.6705 230.09 87.2105C232.01 87.7505 240.64 90.6305 239.02 95.1305C237.4 99.6305 232.36 100.661 232.18 103.381C232 106.101 235.24 110.601 235.24 114.741C235.24 118.881 232.9 127.871 231.82 130.751C230.74 133.631 228.57 135.581 230.09 137.491"
      stroke="#8F8BEF"
      strokeWidth="4"
      strokeMiterlimit="10"
    />
    <path
      d="M142.16 197.699C142.16 197.699 128.92 203.489 116.2 209.299C103.48 215.109 100.16 223.959 98.4996 228.389C96.8396 232.819 92.1396 232.819 88.5396 234.199C84.9396 235.579 81.6296 238.899 78.5796 241.669C75.5296 244.439 64.4696 250.799 58.9396 250.799C53.4096 250.799 39.2996 251.349 34.8796 254.399C30.4596 257.449 16.0696 267.949 24.3696 284.269C32.6696 300.589 58.9396 300.589 70.8396 285.649C82.7396 270.709 94.0696 261.589 102.93 256.609C111.79 251.629 120.63 246.929 124.23 249.699C127.83 252.469 121.19 267.399 124.51 272.929C127.83 278.459 144.43 280.399 150.51 267.669C156.59 254.939 158.53 261.029 161.57 261.579C164.61 262.129 166.25 259.369 168.33 257.979C170.41 256.589 173.7 259.089 174.83 256.599C175.96 254.109 176.51 246.329 178.17 247.589C179.83 248.849 177.89 258.259 182.32 261.569C186.75 264.879 194.49 262.399 199.47 262.949C204.45 263.499 208.6 270.689 218 271.519C227.4 272.349 231 264.049 239.85 261.289C248.7 258.529 260.59 261.009 265.3 261.569C270.01 262.129 283.83 263.509 291.02 254.659C298.21 245.809 292.67 242.209 295.16 241.379C297.65 240.549 315.08 244.699 321.44 235.569C327.8 226.439 323.1 219.529 324.76 215.929C326.42 212.329 332.23 211.779 331.95 208.459C331.67 205.139 326.14 200.589 314.52 205.909C302.9 211.229 292.12 219.519 288.8 221.179C285.48 222.839 282.16 225.879 279.12 224.219C276.08 222.559 266.61 214.699 263.91 209.609"
      stroke="#8F8BEF"
      strokeWidth="4"
      strokeMiterlimit="10"
    />
    <path
      d="M66.8797 151.84C66.8797 151.84 66.4797 159.91 71.4597 162.47C76.4397 165.03 80.9997 168.25 81.8097 170.26C82.6197 172.27 91.3897 185.48 98.4697 187.32C110.83 190.54 122.03 187.58 124.36 187.2"
      stroke="#8F8BEF"
      strokeWidth="4"
      strokeMiterlimit="10"
    />
    <path
      d="M148.82 178.89C148.82 178.89 150.02 179.56 151.92 179.77C153.82 179.98 155.6 178.64 157.13 179.35C158.66 180.06 161.96 181.54 163.8 182.95C165.64 184.36 166.91 185.37 168.12 184.94C169.33 184.51 172.12 181.75 173.27 181.05C174.42 180.35 176.13 178.93 176 177.38C175.87 175.83 174.28 177.31 174.48 174.7C174.68 172.09 176.96 172.44 176.89 170.25C176.82 168.06 173.78 166.51 170.66 165.17C167.54 163.83 164.24 161.36 162.78 160.58C161.32 159.8 159.48 158.32 158.33 157.54C157.18 156.76 156.04 156.41 156.04 156.41"
      stroke="#8F8BEF"
      strokeWidth="4"
      strokeMiterlimit="10"
    />
    <path
      d="M255.86 196.4C277.56 196.4 295.15 181.366 295.15 162.82C295.15 144.275 277.56 129.24 255.86 129.24C234.161 129.24 216.57 144.275 216.57 162.82C216.57 181.366 234.161 196.4 255.86 196.4Z"
      stroke="#E0E2F2"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M294.62 157.309C295.87 160.929 297.47 167.939 297.47 171.939C297.47 193.249 278.31 210.519 254.67 210.519C231.03 210.519 211.87 193.249 211.87 171.939C211.87 164.879 215.7 156.059 219.37 150.369"
      stroke="#E0E2F2"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M255.4 188.11C272.637 188.11 286.61 176.591 286.61 162.38C286.61 148.17 272.637 136.65 255.4 136.65C238.164 136.65 224.19 148.17 224.19 162.38C224.19 176.591 238.164 188.11 255.4 188.11Z"
      stroke="#E0E2F2"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path d="M230.09 147.33L255.4 188.12" stroke="#E0E2F2" strokeWidth="2" strokeMiterlimit="10" />
    <path
      d="M187.28 174.87C177.65 197.9 165.15 209.13 155.52 205.1C145.89 201.07 145.32 183.46 154.95 160.43C164.58 137.4 180.44 124.1 190.07 128.12C199.7 132.15 196.91 151.84 187.28 174.87Z"
      stroke="#E0E2F2"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M159.62 205.899C156.74 206.859 152.88 207.389 150.28 206.329C139.89 202.069 139.24 182.889 149.58 157.679C159.92 132.469 176.99 117.769 187.38 122.029C189.98 123.089 194.48 127.839 194.48 132.509"
      stroke="#E0E2F2"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M175.29 123.76L84.5798 99.22C81.1698 98.31 77.5298 98.68 74.3998 100.32C69.6398 102.81 63.3698 106.03 56.8698 119.18C53.4798 126.04 53.9498 139.4 55.3698 142.96C57.5298 148.38 143.54 198.49 143.54 198.49"
      stroke="#E0E2F2"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M153.11 117.76C153.11 117.76 135.46 120.55 125.7 143.07C115.94 165.59 123.24 186.54 123.24 186.54"
      stroke="#E0E2F2"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M99.21 103.18C99.21 103.18 83.16 108.88 76.53 125.21C69.9 141.54 77.16 158.49 77.16 158.49"
      stroke="#E0E2F2"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M227.543 178.387L224.76 173.994C224.288 173.248 223.299 173.026 222.553 173.499C221.806 173.972 221.584 174.96 222.057 175.707L224.84 180.099C225.312 180.846 226.301 181.068 227.047 180.595C227.794 180.122 228.016 179.134 227.543 178.387Z"
      fill="#E0E2F2"
    />
  </svg>
);

export default GeneralErrorIllustration;
